// javascript requires
import 'jquery';
import 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-touch';
require('../node_modules/material-design-lite/material.min.js');
require('../templates/assets/scripts/initialize-angular.js');
// import styles
import './vendor.module.scss';
